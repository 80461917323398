import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import Link from '../Link';
import Logo, { LOGO_DARK_PINK_DOT } from '../Logo';

const StyledRow = styled(Row)`
  box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.05);
  color: #5b6b7d;
  height: 60px;
  padding: 0 22px;
  position: absolute;
  width: 100%;
  z-index: 2;
`;

class AccountOnboardingHeader extends PureComponent {
  render = () => (
    <StyledRow
      type="flex"
      align="middle"
      justify={this.props.hideLogin ? 'start' : 'space-between'}
    >
      <Logo logo={LOGO_DARK_PINK_DOT} width={60} />
      {!this.props.hideLogin && (
        <div>
          Already have an account? <Link to="/login">Log in</Link>
        </div>
      )}
    </StyledRow>
  );
}

AccountOnboardingHeader.propTypes = {
  hideLogin: PropTypes.bool,
};

export default AccountOnboardingHeader;
